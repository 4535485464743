import { Box, Flex } from "@atoms/Flex";
import {
    ReactNode,
    memo,
    useCallback,
    useEffect,
    useMemo,
    useRef,
} from "react";
import { useTheme } from "styled-components/native";

import { Text } from "@atoms/Text";
import { useAppContext } from "@contexts/app";
import { usePriceContext } from "@contexts/price";
import { gql, useQuery } from "@hooks/useApollo";
import { useMedia } from "@hooks/useMedia";
import { useStoredState } from "@hooks/useStoredState";
import { HorizontalScrollView } from "@molecules/HorizontalScrollView";
import { Select, SelectOptionType } from "@molecules/Select";
import { useTutorialContext } from "@organisms/Tutorial/context";
import { TRADE_HEADER_HEIGHT } from "@screens/Trade";
import { formatLargeNumber, formatPrice } from "@utils";
import { Pair } from "gql/graphql";
import { formatUnits } from "viem";
import { useBlockNumber } from "wagmi";
import {
    LineStatistic,
    Statistic,
} from "../../../../../theme/components/molecules/Statistic";
import { useTradeContext } from "../../../context";
import { PriceChange } from "./PriceChange";
import { usePairStatistics } from "./usePairStatistics";

const MIN_WIDTH = 125;

enum Cell {
    PRICE_CHANGE = "PRICE_CHANGE",
    OPEN_INTEREST_LONG = "OPEN_INTEREST_LONG",
    OPEN_INTEREST_SHORT = "OPEN_INTEREST_SHORT",
    FUNDING = "FUNDING",
    ROLLOVER = "ROLLOVER",
    VOLUME = "VOLUME",
}

const FUNDING_OPTIONS: SelectOptionType[] = [
    {
        value: "1",
        text: "1h",
    },
    {
        value: "8",
        text: "8h",
    },
    {
        value: "24",
        text: "24h",
    },
    {
        value: "8765",
        text: "1y",
    },
];

const PairStatistics = memo(({ pair }: { pair?: Pair }) => {
    const theme = useTheme();
    const media = useMedia();

    const { prices } = usePriceContext();
    const { initial } = useAppContext();
    const { performances } = useTradeContext();

    const { data: blockNumber } = useBlockNumber({
        watch: true,
    });

    const { data } = useQuery(GET_PAIR, {
        skip: !pair,
        variables: {
            id: Number(pair?.id),
        },
        fetchPolicy: "network-only",
        pollInterval: 10000,
    });

    const price = useMemo(
        () => prices?.get(pair?.from as string)?.mid || null,
        [pair?.from, prices]
    );

    const currentPair: Pair = useMemo(() => {
        return data?.pair || initial.pair || null;
    }, [data?.pair, initial.pair]);

    const isStatisticReady = useMemo(() => {
        return !currentPair?.shortOI && !currentPair?.longOI;
    }, [currentPair?.shortOI, currentPair?.longOI]);

    const { fundingRate, rollover, oi } = usePairStatistics({
        pair,
        price,
        blockNumber,
    });

    const [selectedFundingPeriod, setSelectedFundingPeriod] =
        useStoredState<SelectOptionType>(
            FUNDING_OPTIONS[0],
            "TRADE_PAIR_STATISTICS_SELECTED_FUNDING_PERIOD"
        );
    const [selectedRolloverPeriod, setSelectedRolloverPeriod] =
        useStoredState<SelectOptionType>(
            FUNDING_OPTIONS[0],
            "TRADE_PAIR_STATISTICS_SELECTED_ROLLOVER"
        );

    const onChangeFundingPeriod = useCallback(
        (option: SelectOptionType) => {
            setSelectedFundingPeriod(option);
        },
        [setSelectedFundingPeriod]
    );

    const onChangeRolloverPeriod = useCallback(
        (option: SelectOptionType) => {
            setSelectedRolloverPeriod(option);
        },
        [setSelectedRolloverPeriod]
    );

    const fundingLong = useMemo(() => {
        const period =
            Number(selectedFundingPeriod.value) * (10 / 3) * 60 * 60 * 100;
        if (fundingRate?.long) return fundingRate.long * period;

        return 0;
    }, [fundingRate, selectedFundingPeriod.value]);

    const fundingShort = useMemo(() => {
        const period =
            Number(selectedFundingPeriod.value) * (10 / 3) * 60 * 60 * 100;
        if (fundingRate.short) return fundingRate.short * period;
        return 0;
    }, [fundingRate.short, selectedFundingPeriod.value]);

    const rolloverSelected = useMemo(() => {
        const period =
            Number(selectedRolloverPeriod.value) * (10 / 3) * 60 * 60 * 100;
        if (rollover) return rollover * period;
        return 0;
    }, [rollover, selectedRolloverPeriod.value]);

    const volume = useMemo(() => {
        if (currentPair?.volume)
            return Number(formatUnits(currentPair?.volume, 6));
        return 0;
    }, [currentPair]);

    const MOBILE_WIDTH = 120;
    const itemWidth = media.isMobile ? "auto" : MIN_WIDTH;

    const { addStep } = useTutorialContext();
    const containerRef = useRef<Element>(null);

    useEffect(() => {
        addStep({
            id: "pair-statistics",
            ref: containerRef,
            title: "Pair Statistics",
            content: "Here you can see the statistics of the pair.",
        });
    }, [addStep]);

    const renderOpenInterestLong = useMemo(() => {
        return (
            <Statistic
                small
                noIcon
                loading={isStatisticReady}
                label="Open Interest (L)"
                selectable={false}
                value={`${
                    oi?.long.current <= 0
                        ? 0
                        : formatLargeNumber(oi?.long.current)
                } / ${formatLargeNumber(oi?.long.max)}`}
                tooltip={{
                    title: "Open Interest (L)",
                    position: "bottom-left",
                    minWidth: true,
                    content:
                        "Sum of long position sizes / Available long open interest",
                    renderContent: (
                        <Box gap={theme.spacing.small}>
                            <Box gap={theme.spacing.small}>
                                <Flex justify="space-between">
                                    <Statistic
                                        small
                                        value={`${
                                            oi.long.current <= 0
                                                ? 0
                                                : formatPrice(oi.long.current, {
                                                      currency: true,
                                                      decimals: 2,
                                                  })
                                        }`}
                                        label="Current"
                                    />
                                    <Statistic
                                        small
                                        right
                                        value={`${formatLargeNumber(
                                            oi.long.max
                                        )}`}
                                        label="Max"
                                    />
                                </Flex>

                                <ProportionBar
                                    min={0}
                                    max={oi.long.max}
                                    value={oi.long.current}
                                />
                            </Box>
                        </Box>
                    ),
                    style: {
                        height: "100%",
                        justifyContent: "center",
                    },
                }}
                style={{
                    paddingHorizontal: theme.spacing.bigger,
                    minWidth: itemWidth,
                    width: "100%",
                    height: TRADE_HEADER_HEIGHT,
                    justifyContent: "center",
                }}
            />
        );
    }, [
        isStatisticReady,
        itemWidth,
        oi.long,
        theme.spacing.bigger,
        theme.spacing.small,
    ]);

    const renderOpenInterestShort = useMemo(() => {
        return (
            <Statistic
                small
                noIcon
                loading={isStatisticReady}
                selectable={false}
                label="Open Interest (S)"
                value={`${
                    oi.short.current <= 0
                        ? 0
                        : formatLargeNumber(oi.short.current)
                } / ${formatLargeNumber(oi.short.max)}`}
                tooltip={{
                    title: "Open Interest (S)",
                    position: "bottom-left",
                    minWidth: true,
                    content:
                        "Sum of short position sizes / Available short open interest",
                    renderContent: (
                        <Box gap={theme.spacing.small}>
                            <Box gap={theme.spacing.small}>
                                <Flex justify="space-between">
                                    <Statistic
                                        small
                                        value={`${
                                            oi.short.current <= 0
                                                ? 0
                                                : formatPrice(
                                                      oi.short.current,
                                                      {
                                                          currency: true,
                                                          decimals: 2,
                                                      }
                                                  )
                                        }`}
                                        label="Current"
                                    />
                                    <Statistic
                                        right
                                        small
                                        value={`${formatLargeNumber(
                                            oi.short.max
                                        )}`}
                                        label="Max"
                                    />
                                </Flex>

                                <ProportionBar
                                    min={0}
                                    max={oi.short.max}
                                    value={oi.short.current}
                                />
                            </Box>
                        </Box>
                    ),
                    style: {
                        height: "100%",
                        justifyContent: "center",
                    },
                }}
                style={{
                    paddingHorizontal: theme.spacing.bigger,
                    height: TRADE_HEADER_HEIGHT,
                    justifyContent: "center",
                }}
            />
        );
    }, [isStatisticReady, oi.short, theme.spacing.bigger, theme.spacing.small]);

    const renderVolume = useMemo(() => {
        return (
            <Statistic
                noIcon
                small
                loading={isStatisticReady}
                label="Volume"
                value={formatLargeNumber(volume)}
                tooltip={{
                    title: "Volume",
                    position: "bottom-left",
                    content: "Cumulative volume",
                    minWidth: 225,
                    style: {
                        height: "100%",
                        justifyContent: "center",
                    },
                }}
                style={{
                    height: TRADE_HEADER_HEIGHT,
                    paddingHorizontal: theme.spacing.bigger,
                    justifyContent: "center",
                }}
            />
        );
    }, [isStatisticReady, theme.spacing.bigger, volume]);

    const availableCells: Cell[] = useMemo(() => {
        return [
            Cell.PRICE_CHANGE,
            Cell.OPEN_INTEREST_LONG,
            Cell.OPEN_INTEREST_SHORT,
            Cell.FUNDING,
            Cell.ROLLOVER,
            Cell.VOLUME,
        ];
    }, []);

    const renderCell = useMemo(
        () => (item: Cell) => {
            switch (item) {
                case Cell.PRICE_CHANGE:
                    return (
                        <PriceChange
                            pair={pair}
                            performance={performances?.get(
                                pair?.from as string
                            )}
                            loading={!performances || !pair}
                        />
                    );
                case Cell.OPEN_INTEREST_LONG:
                    return renderOpenInterestLong;
                case Cell.OPEN_INTEREST_SHORT:
                    return renderOpenInterestShort;
                case Cell.FUNDING:
                    return (
                        <Funding
                            loading={isStatisticReady}
                            long={fundingLong}
                            short={fundingShort}
                            onChange={onChangeFundingPeriod}
                            selected={selectedFundingPeriod}
                        />
                    );
                case Cell.ROLLOVER:
                    return (
                        <Rollover
                            loading={isStatisticReady}
                            rollover={rolloverSelected}
                            selected={selectedRolloverPeriod}
                            onChange={onChangeRolloverPeriod}
                        />
                    );
                case Cell.VOLUME:
                    return renderVolume;
                default:
                    return null;
            }
        },
        [
            fundingLong,
            fundingShort,
            isStatisticReady,
            onChangeFundingPeriod,
            onChangeRolloverPeriod,
            pair,
            performances,
            renderOpenInterestLong,
            renderOpenInterestShort,
            renderVolume,
            rolloverSelected,
            selectedFundingPeriod,
            selectedRolloverPeriod,
        ]
    );

    return (
        <Box flex={media.isMobile ? undefined : 1} ref={containerRef}>
            {/* <LinearGradient
                // Button Linear Gradient
                start={{ x: 1, y: 1 }}
                pointerEvents="none"
                colors={[rgba(theme.color.black, 1), "transparent"]}
                style={{
                    position: "absolute",
                    width: 60,
                    right: 0,
                    top: 0,
                    bottom: 0,
                    zIndex: 100,
                    borderTopRightRadius: theme.radius.big,
                    borderBottomRightRadius: theme.radius.big,
                }}
            /> */}

            {media.isMobile ? (
                <Flex
                    style={{
                        flexWrap: "wrap",
                        width: "100%",
                    }}
                >
                    {availableCells?.map((cell) => {
                        return (
                            <CellItem key={`pair-statistic-${cell}`}>
                                {renderCell(cell)}
                            </CellItem>
                        );
                    })}
                </Flex>
            ) : (
                <HorizontalScrollView
                    minWidth={MOBILE_WIDTH}
                    contentContainerStyle={{
                        alignItems: "center",
                        flexWrap: "wrap",
                    }}
                >
                    {availableCells?.map((cell, index) => {
                        return (
                            <CellItem key={`pair-statistic-${cell}`}>
                                {renderCell(cell)}
                                {media.isMobile ||
                                index === availableCells.length - 1 ? null : (
                                    <Line />
                                )}
                            </CellItem>
                        );
                    })}
                </HorizontalScrollView>
            )}
        </Box>
    );
});

const CellItem = memo(({ children }: { children: ReactNode }) => {
    return (
        <Flex
            align="center"
            justify="space-between"
            style={{
                minWidth: MIN_WIDTH,
            }}
        >
            {children}
        </Flex>
    );
});

export const Line = memo(
    ({ height, width }: { height?: number; width?: number }) => {
        const theme = useTheme();
        return (
            <Box
                style={{
                    height: height || 32,
                    width: width || 1,
                    backgroundColor: theme.color.rgba(theme.color.white, 0.1),
                }}
            />
        );
    }
);

const ProportionBar = memo(
    ({
        value,
        min,
        max,
        line,
    }: {
        value: number;
        min: number;
        max: number;
        line?: boolean;
    }) => {
        const theme = useTheme();
        const width = ((value - min) / (max - min)) * 100;

        return (
            <Flex
                red
                style={{
                    height: 5,
                    width: "100%",
                    borderRadius: theme.radius.small,
                    backgroundColor: theme.color.rgba(theme.color.white, 0.2),
                }}
            >
                <Box
                    style={
                        line
                            ? {
                                  backgroundColor: theme.color.primary,
                                  left: `${width}%`,
                                  height: 10,
                                  width: 2,
                                  marginTop: -2,
                              }
                            : {
                                  backgroundColor: theme.color.rgba(
                                      theme.color.white,
                                      0.4
                                  ),
                                  height: "100%",
                                  width: `${width}%`,
                                  borderTopLeftRadius: 4,
                                  borderBottomLeftRadius: 4,
                              }
                    }
                />
            </Flex>
        );
    }
);

const GET_PAIR = gql`
    query StatisticsPair($id: ID!) @api(name: subgraph) {
        pair(id: $id) {
            id
            from
            feed
            maxOI
            shortOI
            longOI
            volume
            accFundingLong
            accFundingShort
            accRollover
            curRollover
            curFundingLong
            curFundingShort
            lastFundingRate
            lastFundingVelocity
            maxFundingFeePerBlock
            lastFundingBlock
            lastFundingTime
            rolloverFeePerBlock
            tradeSizeRef
        }
    }
`;

export default PairStatistics;

const Rollover = memo(
    ({
        loading,
        rollover,
        selected,
        onChange,
    }: {
        loading: boolean;
        rollover: number;
        selected: SelectOptionType;
        onChange: (option: SelectOptionType) => void;
    }) => {
        const theme = useTheme();
        return (
            <Box style={{ position: "relative", minWidth: 146 }}>
                <Box
                    style={{
                        position: "absolute",
                        top: theme.spacing.small + 1,
                        left: 90,
                        zIndex: 100,
                    }}
                >
                    <Select
                        small
                        options={FUNDING_OPTIONS}
                        selected={selected}
                        onChange={onChange}
                    />
                </Box>
                <Statistic
                    small
                    noIcon
                    loading={loading}
                    label="Rollover Fee"
                    selectable={false}
                    value={`${formatPrice(rollover, { decimals: 4 })}%`}
                    tooltip={{
                        title: `Estimated ${selected.text} Rollover Fee`,
                        position: "bottom-left",
                        minWidth: true,
                        renderContent: (
                            <Box
                                gap={theme.spacing.small}
                                style={{
                                    paddingTop: theme.spacing.small,
                                }}
                            >
                                <Text
                                    smaller
                                    color={theme.color.rgba(
                                        theme.color.white,
                                        0.6
                                    )}
                                    lineHeight={theme.text.big}
                                >
                                    {`Rollover fee scales to reflect changes in volatility risk.\nCompounds every block.`}
                                </Text>
                                <Box gap={theme.spacing.tiny}>
                                    <LineStatistic
                                        label="Rollover"
                                        value={
                                            rollover ? `${rollover}%` : "None"
                                        }
                                    />
                                </Box>
                            </Box>
                        ),
                        style: {
                            height: "100%",
                            justifyContent: "center",
                        },
                    }}
                    style={{
                        height: TRADE_HEADER_HEIGHT,
                        paddingHorizontal: theme.spacing.bigger,
                        justifyContent: "center",
                    }}
                />
            </Box>
        );
    }
);

const Funding = memo(
    ({
        selected,
        onChange,
        loading,
        long,
        short,
    }: {
        selected: SelectOptionType;
        onChange: (option: SelectOptionType) => void;
        loading: boolean;
        long: number;
        short: number;
    }) => {
        const theme = useTheme();

        return (
            <Box style={{ position: "relative", minWidth: 150 }}>
                <Box
                    style={{
                        position: "absolute",
                        top: theme.spacing.small + 1,
                        left: 96,
                        zIndex: 100,
                    }}
                >
                    <Select
                        small
                        options={FUNDING_OPTIONS}
                        selected={selected}
                        onChange={onChange}
                    />
                </Box>
                <Statistic
                    small
                    noIcon
                    loading={loading}
                    selectable={false}
                    label="Funding (L/S)"
                    tooltip={{
                        position: "bottom-left",
                        minWidth: true,
                        title: `Estimated ${selected.text} Funding (L/S)`,
                        renderContent: (
                            <Box
                                gap={theme.spacing.small}
                                style={{
                                    paddingTop: theme.spacing.small,
                                }}
                            >
                                <Text
                                    smaller
                                    color={theme.color.rgba(
                                        theme.color.white,
                                        0.6
                                    )}
                                    lineHeight={theme.text.big}
                                >
                                    {`Funding rate scales to reduce the imbalance between longs and shorts.\nCompounds every block.`}
                                </Text>
                                <Box gap={theme.spacing.tiny}>
                                    <LineStatistic
                                        value={long ? `${long}%` : "None"}
                                        label="Long"
                                    />
                                    <LineStatistic
                                        value={short ? `${short}%` : "None"}
                                        label="Short"
                                    />
                                </Box>
                            </Box>
                        ),
                        style: {
                            height: "100%",
                            justifyContent: "center",
                        },
                    }}
                    style={{
                        paddingHorizontal: theme.spacing.bigger,
                        height: TRADE_HEADER_HEIGHT,
                        justifyContent: "center",
                    }}
                >
                    <Flex align="center">
                        <Text
                            smaller
                            bold
                            mono
                            color={theme.color.rgba(
                                theme.color.white,
                                long ? 1 : 0.4
                            )}
                        >
                            {long
                                ? `${formatPrice(long, {
                                      currency: false,
                                      decimals: 4,
                                  })}%`
                                : "None"}
                        </Text>
                        <Text
                            smaller
                            semiBold
                            mono
                            color={theme.color.rgba(theme.color.white, 0.4)}
                        >
                            {` / `}
                        </Text>
                        <Text
                            smaller
                            bold
                            mono
                            color={theme.color.rgba(
                                theme.color.white,
                                short ? 1 : 0.4
                            )}
                        >
                            {short
                                ? `${formatPrice(short, {
                                      decimals: 4,
                                      currency: false,
                                  })}%`
                                : "None"}
                        </Text>
                    </Flex>
                </Statistic>
            </Box>
        );
    }
);
