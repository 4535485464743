import { Box, Flex } from "@atoms/Flex";
import { Icon } from "@atoms/Icon";
import { AnimatedText, Text } from "@atoms/Text";
import { useMedia } from "@hooks/useMedia";
import { Tooltip } from "@molecules/Tooltip";
import { memo, useMemo, useState } from "react";
import { Pressable } from "react-native";
import Animated, {
    Easing,
    interpolateColor,
    useAnimatedStyle,
    useDerivedValue,
    withTiming,
} from "react-native-reanimated";
import { Link } from "solito/link";
import { useTheme } from "styled-components/native";

export const NavigationLink = memo(
    ({
        text,
        isActive,
        items,
        small,
        disabled,
        url = "#",
        onPress,
    }: {
        text: string;
        isActive?: boolean;
        disabled?: boolean;
        url?: string;
        small?: boolean;
        items?: {
            text: string;
            url: string;
            external?: boolean;
            icon?: string;
        }[];
        onPress?: () => void;
    }) => {
        const media = useMedia();
        const theme = useTheme();

        const [isHovering, setIsHovering] = useState(false);

        const backgroundAnimation = useDerivedValue(() => {
            return withTiming(isActive ? 2 : isHovering ? 1 : 0, {
                duration: 150,
                easing: Easing.bezier(0.25, 0.1, 0.25, 1),
            });
        }, [isActive, isHovering]);

        const textColorStyle = useAnimatedStyle(() => {
            return {
                color: interpolateColor(
                    backgroundAnimation.value,
                    [0, 1, 2],
                    [
                        theme.color.rgba(theme.color.white, 0.4),
                        theme.color.rgba(theme.color.white, 0.8),
                        theme.color.white,
                    ]
                ),
            };
        }, [isActive, isHovering, backgroundAnimation, theme.color.white]);

        if (items?.length)
            return (
                <Tooltip
                    position="bottom-right"
                    style={{
                        height: "100%",
                        paddingVertical: theme.spacing.small,
                    }}
                    renderContent={
                        <Box
                            style={{
                                margin: -theme.spacing.bigger,
                            }}
                        >
                            {items?.map((item, index) => {
                                const isFirst = index === 0;
                                const isLast = index === items.length - 1;
                                return (
                                    <SubmenuItem
                                        isFirst={isFirst}
                                        isLast={isLast}
                                        key={`submenu-item-${item.url}`}
                                        {...item}
                                    />
                                );
                            })}
                        </Box>
                    }
                >
                    <LinkButton>
                        <AnimatedText
                            selectable={false}
                            semiBold
                            bigger={media.isMobile && !small}
                            // small={!media.isMobile || small}
                            primary={isActive}
                            style={[
                                textColorStyle,
                                { marginRight: theme.spacing.tiny },
                            ]}
                        >
                            {text}
                        </AnimatedText>
                        <Icon
                            name="caret"
                            size={10}
                            color={theme.color.rgba(
                                theme.color.white,
                                isHovering ? 1 : 0.4
                            )}
                            rotate={isHovering ? -90 : 90}
                        />
                    </LinkButton>
                </Tooltip>
            );

        if (disabled) {
            return (
                <Box
                    style={{
                        height: media.isMobile ? undefined : "100%",
                    }}
                    onMouseEnter={() => {
                        setIsHovering(true);
                    }}
                    onMouseLeave={() => setIsHovering(false)}
                >
                    <Tooltip
                        content="Coming soon"
                        position="bottom-left"
                        tiny
                        style={{
                            height: "100%",
                            paddingVertical: theme.spacing.small,
                        }}
                        contentProps={{
                            numberOfLines: 1,
                        }}
                    >
                        <LinkButton>
                            {/* @ts-ignore */}
                            <AnimatedText
                                bigger={media.isMobile && !small}
                                // small={!media.isMobile || small}
                                selectable={false}
                                semiBold
                                primary={isActive}
                                style={[textColorStyle]}
                            >
                                {text}
                            </AnimatedText>
                        </LinkButton>
                    </Tooltip>
                </Box>
            );
        }

        return (
            <Box
                style={{
                    height: media.isMobile ? undefined : "100%",
                }}
                onMouseEnter={() => {
                    setIsHovering(true);
                }}
                onMouseLeave={() => setIsHovering(false)}
            >
                <Link
                    suppressHydrationWarning
                    href={url}
                    viewProps={{
                        style: media.isMobile
                            ? {
                                  paddingVertical: theme.spacing.small,
                              }
                            : {
                                  height: "100%",
                                  alignItems: "center",
                                  justifyContent: "center",
                              },
                    }}
                    style={{
                        height: "100%",
                    }}
                >
                    <LinkButton>
                        {/* @ts-ignore */}
                        <AnimatedText
                            bigger={media.isMobile && !small}
                            // small={!media.isMobile || small}
                            selectable={false}
                            semiBold
                            primary={isActive}
                            style={textColorStyle}
                        >
                            {text}
                        </AnimatedText>
                    </LinkButton>
                </Link>
            </Box>
        );
    }
);

const SubmenuItem = memo(
    ({
        text,
        url,
        icon,
        external,
        isFirst,
        isLast,
    }: {
        text: string;
        url: string;
        external?: boolean;
        icon?: string;
        isFirst: boolean;
        isLast: boolean;
    }) => {
        const theme = useTheme();
        const [isHovering, setIsHovering] = useState(false);

        const style = useAnimatedStyle(() => {
            return {
                opacity: withTiming(isHovering ? 1 : 0.6, {
                    duration: 150,
                    easing: Easing.bezier(0.25, 0.1, 0.25, 1),
                }),
            };
        }, [isHovering]);

        const renderContent = useMemo(() => {
            return (
                <Animated.View
                    style={[
                        style,
                        { flexDirection: "row", alignItems: "center" },
                    ]}
                >
                    <Box
                        style={{
                            width: 18,
                        }}
                    >
                        {icon ? (
                            <Icon name={icon} size={16} />
                        ) : (
                            <Text big bold size={16} style={{ marginTop: -6 }}>
                                🜛
                            </Text>
                        )}
                    </Box>

                    <Text
                        smallest
                        semiBold
                        whiteSpace="nowrap"
                        style={{
                            marginLeft: theme.spacing.smaller,
                        }}
                    >
                        {text}
                    </Text>
                </Animated.View>
            );
        }, [icon, style, text, theme.spacing.smaller]);

        const linkStyle = {
            padding: theme.spacing.medium,
            paddingTop: isFirst ? theme.spacing.medium : theme.spacing.smaller,
            paddingBottom: isLast
                ? theme.spacing.medium
                : theme.spacing.smaller,
        };

        if (external) {
            return (
                <a
                    href={url}
                    onMouseEnter={() => setIsHovering(true)}
                    onMouseLeave={() => setIsHovering(false)}
                    target="_blank"
                    style={linkStyle}
                >
                    {renderContent}
                </a>
            );
        }

        return (
            <Link
                href={url}
                prefetch={false}
                suppressHydrationWarning
                onMouseEnter={() => setIsHovering(true)}
                onMouseLeave={() => setIsHovering(false)}
                style={linkStyle}
            >
                {renderContent}
            </Link>
        );
    }
);

const LinkButton = memo(
    ({ children }: { children: JSX.Element | JSX.Element[] }) => {
        const theme = useTheme();
        const media = useMedia();
        return (
            <Box
                // align="center"
                justify="center"
                style={{
                    height: "100%",
                }}
            >
                <Flex
                    align="center"
                    style={{
                        paddingHorizontal: media.isMobile
                            ? theme.spacing.big
                            : theme.spacing.small,
                    }}
                >
                    {children}
                </Flex>
            </Box>
        );
    }
);
