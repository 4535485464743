import { Box, Flex } from "@atoms/Flex";
import { useMedia } from "@hooks/useMedia";
import { useRouting } from "expo-next-react-navigation";
import { Pressable } from "react-native";
import { NavigationLink } from "./Link";
import { memo } from "react";
import { Collapsible } from "@molecules/Animation";
import { Text } from "@atoms/Text";
import { useTheme } from "styled-components/native";

export const NAVIGATION_MORE_LINKS = [
    {
        text: "Home",
        external: true,
        url: "https://ostium.io",
    },
    {
        text: "Docs",
        icon: "gitbook",
        external: true,
        url: "https://ostium-labs.gitbook.io/ostium-docs/",
    },
    {
        text: "Twitter",
        icon: "twitter",
        external: true,
        url: "https://twitter.com/ostiumlabs",
    },
    {
        text: "Discord",
        icon: "discord",
        external: true,
        url: "https://discord.gg/44hxSkfy9a",
    },
    {
        text: "Blog",
        icon: "medium",
        external: true,
        url: "https://www.ostium.io/blog",
    },
];

export type NavigationLinkType = {
    text: string;
    url?: string;
    isActive?: boolean;
    items?: {
        text: string;
        url: string;
        isActive?: boolean;
    }[];
};

export const Navigation = memo(({ items }: { items: NavigationLinkType[] }) => {
    const media = useMedia();
    const { pathname } = useRouting();

    return (
        <Flex
            flex={media.isMobile ? 1 : undefined}
            style={{
                height: "100%",
                justifyContent: media.isMobile ? "center" : "flex-start",
            }}
        >
            {items.map((i, index) => (
                <NavigationLink
                    key={`${i.url}-${index}`}
                    text={i.text}
                    url={i.url}
                    isActive={i?.isActive || pathname === i.url}
                    items={i?.items}
                />
            ))}
        </Flex>
    );
});

export const NavigationMobile = memo(
    ({
        items,
        onToggle,
    }: {
        items: NavigationLinkType[];
        onToggle: () => void;
    }) => {
        const { pathname } = useRouting();

        const theme = useTheme();
        return (
            <>
                <Box>
                    {items.map((i) => (
                        <>
                            {i?.items?.length ? (
                                <Collapsible
                                    renderHeader={
                                        <Box
                                            style={{
                                                padding: theme.spacing.big,
                                                paddingVertical:
                                                    theme.spacing.small,
                                            }}
                                        >
                                            <Text
                                                bigger
                                                semiBold
                                                color={theme.color.rgba(
                                                    theme.color.white,
                                                    0.4
                                                )}
                                            >
                                                More
                                            </Text>
                                        </Box>
                                    }
                                >
                                    {NAVIGATION_MORE_LINKS.map((i) => (
                                        <NavigationLink
                                            small
                                            key={i.url}
                                            text={i.text}
                                            url={i.url}
                                        />
                                    ))}
                                </Collapsible>
                            ) : (
                                <Pressable key={i.url} onPress={onToggle}>
                                    <NavigationLink
                                        text={i.text}
                                        url={i.url}
                                        isActive={
                                            i?.isActive || pathname === i.url
                                        }
                                        items={i?.items}
                                    />
                                </Pressable>
                            )}
                        </>
                    ))}
                </Box>
            </>
        );
    }
);
