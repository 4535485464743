import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useAppContext } from "@contexts/app";
import { useNotificationContext } from "@contexts/notification";
import { Box, Flex } from "@atoms/Flex";
import { Text } from "@atoms/Text";
import { Button } from "@molecules/Button";
import { Link } from "solito/link";
import { useTheme } from "styled-components/native";
import { useAccount } from "wagmi";
import { TextInput } from "react-native";
import { InfinityLoader } from "@atoms/LoadingIndicator";
import Modal from "@organisms/Modal";
import { useUser } from "@contexts/hooks/useUser";
import { useRouting } from "expo-next-react-navigation";
import { ConnectWithStrictView } from "./ConnectWithStrictView";
import { useRouter } from "next/router";

export const AccessCodeModal = () => {
    const theme = useTheme();
    const [isAccessCodeModalVisible, setAccessCodeModalVisible] =
        useState(true);
    const [error, setError] = useState(false);
    const [showConnectModal, setShowConnectModal] = useState(true);
    const toggleAccessCodeModal = useCallback(() => {
        setAccessCodeModalVisible(!isAccessCodeModalVisible);
    }, [isAccessCodeModalVisible]);
    const { address, isConnected } = useAccount();
    const { addReferral, refetchUser } = useUser({ address });
    const [loading, setLoading] = useState(false);

    const toggleConnectModal = useCallback(
        (newStatus: string, show: undefined | boolean) => {
            //@ts-ignore
            setStatus(newStatus);
            setShowConnectModal(show ? show : !showConnectModal);
        },
        [showConnectModal]
    );

    const [status, setStatus] = useState<
        "initial" | "loading" | "success" | "fail" | "connect"
    >("initial");

    if (status === "connect") {
        return (
            <ConnectWithStrictView
                showConnectModal={showConnectModal}
                toggleConnectModal={() =>
                    toggleConnectModal("initial", undefined)
                }
            />
        );
    }

    if (status === "success") {
        return (
            <Modal
                isVisible={isAccessCodeModalVisible}
                title={"Welcome to Ostium"}
                strict={true}
            >
                <Flex
                    gap={theme.spacing.massive}
                    direction="column"
                    style={{
                        paddingHorizontal: theme.spacing.bigger,
                        paddingBottom: theme.spacing.bigger,
                    }}
                >
                    <Text
                        smaller
                        color={theme.color.rgba(theme.color.white, 0.7)}
                        lineHeight={theme.spacing.bigger}
                    >
                        {isConnected && address
                            ? "Click on start to begin trading!"
                            : "Connect your wallet to start trading!"}
                    </Text>
                    <Flex gap={theme.spacing.medium} justify="end">
                        {isConnected && address ? (
                            <Button
                                text="Start Trading"
                                primary
                                disabled={loading}
                                loading={loading}
                                onPress={async () => {
                                    setLoading(true);
                                    await addReferral(address, undefined);
                                    await refetchUser();
                                    setLoading(false);
                                    toggleAccessCodeModal();
                                }}
                            />
                        ) : (
                            <Button
                                text="Connect wallet"
                                primary
                                onPress={() => {
                                    toggleConnectModal("connect", true);
                                }}
                            />
                        )}
                    </Flex>
                </Flex>
            </Modal>
        );
    }

    if (status === "loading") {
        return (
            <Modal
                isVisible={isAccessCodeModalVisible}
                title={"Validating your code"}
                strict={true}
                style={{ height: 300 }}
            >
                <Box
                    align="center"
                    justify="center"
                    gap={theme.spacing.huge}
                    style={{ height: "300px" }}
                >
                    <InfinityLoader />
                    <Text>This might take a few seconds...</Text>
                </Box>
            </Modal>
        );
    }

    if (status === "initial") {
        return (
            <Modal
                isVisible={isAccessCodeModalVisible}
                title={"Welcome to Ostium"}
                strict={true}
            >
                <AccessCode
                    onClose={toggleAccessCodeModal}
                    setStatus={setStatus}
                    error={error}
                    setError={setError}
                    isConnected={isConnected}
                />
            </Modal>
        );
    }
};

export const AccessCode = ({
    onClose,
    setStatus,
    error,
    setError,
    isConnected,
}: any) => {
    return (
        <AccessCodeInput
            onClose={onClose}
            setStatus={setStatus}
            error={error}
            setError={setError}
            isConnected={isConnected}
        />
    );
};

export const AccessCodeInput = ({
    setStatus,
    error,
    setError,
    isConnected,
}: any) => {
    const theme = useTheme();
    const { navigate } = useRouting();
    const { userFE, refetchUser } = useAppContext();
    const { address } = useAccount();
    const { validateAccessCode } = useUser({ address });
    const inputRefs = useRef<(TextInput | null)[]>([]);
    const [code, setCode] = useState(["", "", "", "", ""]);
    const { query } = useRouter();
    const { ref } = query;

    useEffect(() => {
        if (ref && ref.length) {
            //@ts-ignore
            const firstFourChars = ref.slice(0, 5).toUpperCase().split(""); /// Split the first 4 characters

            // Set the first 4 characters in the state, and keep the rest as empty strings
            setCode((prevCode) => [
                ...firstFourChars,
                ...prevCode.slice(firstFourChars.length),
            ]);
        }
    }, [ref]);

    const handleChangeText = (text: string, index: number) => {
        // Detect if the entire code is being pasted
        if (text.length > 1) {
            const newCode = text.slice(0, 5).toUpperCase().split("");
            setCode(newCode);

            // Move focus to the next input or submit the form
            (inputRefs.current[4] as TextInput | null)?.focus?.();
            return;
        }

        // Handle regular character input
        const newCode = [...code];
        newCode[index] = text.toUpperCase();
        setCode(newCode);

        if (text.length === 1 && index < inputRefs.current.length - 1) {
            (inputRefs.current[index + 1] as TextInput | null)?.focus?.();
        }
    };

    const handleKeyPress = ({ nativeEvent }: any, index: number) => {
        if (nativeEvent.key === "Backspace" && index > 0 && !code[index]) {
            (inputRefs.current[index - 1] as TextInput | null)?.focus?.();
        }
    };

    useEffect(() => {
        if (address) {
            refetchUser();
        }
    }, [address, refetchUser]);

    const fullCode = useMemo(() => code.join(""), [code]);

    const isValidFiveDigitCode = fullCode.length === 5 || fullCode === "";

    const isReferSelf =
        fullCode.toUpperCase() === userFE?.referralCode?.toUpperCase();

    const onValidateAccessCode = useCallback(async () => {
        if (!isValidFiveDigitCode) return;
        setStatus("loading");
        const data = await validateAccessCode(fullCode.toUpperCase());
        if (data) {
            const { loading, isAccessCodeValid } = data;
            if (loading) {
                setStatus("loading");
            } else if (isAccessCodeValid) {
                setStatus("success");
            } else {
                setError(true);
                setStatus("initial");
            }
        }
    }, [
        isValidFiveDigitCode,
        setStatus,
        validateAccessCode,
        fullCode,
        setError,
    ]);

    return (
        <Box
            style={{
                padding: theme.spacing.bigger,
                paddingTop: 0,
            }}
            gap={theme.spacing.huge}
        >
            <Text
                smaller
                color={theme.color.rgba(theme.color.white, 0.7)}
                lineHeight={theme.spacing.bigger}
            >
                Enter your access code to trade exclusive real world assets on
                Mainnet.
            </Text>
            <Box
                style={{
                    display: "grid",
                    gridTemplateColumns: "repeat(5, minmax(0, 1fr))",
                }}
                gap={theme.spacing.bigger}
            >
                {[0, 1, 2, 3, 4].map((index) => (
                    <TextInput
                        key={index}
                        ref={(ref) => (inputRefs.current[index] = ref)}
                        value={code[index]}
                        onChangeText={(text) => {
                            if (error) setError(false);
                            handleChangeText(text.toUpperCase(), index);
                        }}
                        onKeyPress={(e) => handleKeyPress(e, index)}
                        style={{
                            borderWidth: 1,
                            borderColor: !isValidFiveDigitCode
                                ? theme.color.red
                                : "",
                            backgroundColor: theme.color.rgba(
                                theme.color.white,
                                0.1
                            ),
                            color: theme.color.white,
                            textAlign: "center",
                            fontSize: 24,
                            height: 80,
                            borderRadius: 10,
                        }}
                    />
                ))}
            </Box>

            {!isValidFiveDigitCode && (
                <Text
                    smaller
                    color={theme.color.rgba(theme.color.red, 0.9)}
                    lineHeight={theme.spacing.smaller}
                >
                    Enter a valid 5 character access code.
                </Text>
            )}

            {isReferSelf && (
                <Text
                    smaller
                    color={theme.color.rgba(theme.color.red, 0.9)}
                    lineHeight={theme.spacing.smaller}
                >
                    You cannot use your own referral code.
                </Text>
            )}

            {error && (
                <Text
                    smaller
                    color={theme.color.rgba(theme.color.red, 0.9)}
                    lineHeight={theme.spacing.smaller}
                >
                    Entered access code is not valid.
                </Text>
            )}

            <Flex gap={theme.spacing.medium} justify="end">
                <Button
                    text="Return to home"
                    primary={false}
                    onPress={() =>
                        navigate({
                            routeName: "/",
                        })
                    }
                />
                <Button
                    text="Unlock mainnet"
                    primary
                    disabled={isReferSelf || !isValidFiveDigitCode}
                    onPress={onValidateAccessCode}
                />
            </Flex>

            <Box
                style={{
                    padding: theme.spacing.bigger,
                    paddingTop: 0,
                }}
                gap={theme.spacing.medium}
            >
                <Text
                    smaller
                    color={theme.color.rgba(theme.color.white, 0.7)}
                    lineHeight={theme.spacing.bigger}
                    textAlign="center"
                >
                    {`Don't have an access code? Head to `}
                    <Link
                        href="https://discord.com/channels/1034965579947315331/1075608722941939733"
                        target="_blank"
                    >
                        <Text
                            primary
                            smaller
                            style={{
                                color: "rgba(255, 77, 21, 0.70)",
                            }}
                        >
                            {`Ostium Discord`}
                        </Text>
                    </Link>
                </Text>

                {isConnected ? null : (
                    <Text
                        smaller
                        color={theme.color.rgba(theme.color.white, 0.7)}
                        lineHeight={theme.spacing.smaller}
                        textAlign="center"
                    >
                        {`Already registered? `}
                        <a
                            onClick={() => {
                                setStatus("connect");
                            }}
                        >
                            <Text
                                primary
                                smaller
                                style={{
                                    color: "rgba(255, 77, 21, 0.70)",
                                    cursor: "pointer",
                                }}
                            >
                                {`Log in to your wallet`}
                            </Text>
                        </a>
                    </Text>
                )}
            </Box>
        </Box>
    );
};
