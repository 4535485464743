export const Colors = {
    white: "#D0DBDA",
    black: "#070606",
    primary: "#FF5A19",
    background: "#111111",
    red: "#BC244A",
    yellow: "#FFC107",
    green: "#1A965D",
    grey: "#d0dbda",
};
