import { Box, Flex } from "@atoms/Flex";
import { Text } from "@atoms/Text";
import { useAppContext } from "@contexts/app";
import { useWeb3Context } from "@contexts/web3";
import { useMedia } from "@hooks/useMedia";
import { Button } from "@molecules/Button";
import { CTA_HEIGHT } from "@screens/components/Header";
import { memo, useMemo } from "react";
import { useTheme } from "styled-components/native";

export const AppAlert = memo(() => {
    const { isOtherNetwork, switchChain, currentChain } = useWeb3Context();
    const media = useMedia();
    const theme = useTheme();
    const { isCTAActive } = useAppContext();

    const isAlert = useMemo(() => {
        if (isOtherNetwork) return true;
    }, [isOtherNetwork]);

    if (isOtherNetwork) {
        return (
            <Flex
                align="center"
                justify="center"
                gap={theme.spacing.smallest}
                style={{
                    height: isCTAActive ? CTA_HEIGHT : 0,
                    backgroundColor: theme.color.rgba(theme.color.red, 0.7),
                }}
            >
                <Text
                    smaller
                    smallest={media.isMobile}
                    semiBold
                    style={{ textAlign: "center" }}
                >
                    {`Please switch to Arbitrum to use the app.`}
                </Text>
                <Button
                    tiny
                    onPress={switchChain}
                    text="Switch Network"
                    white
                    style={{ height: 20 }}
                />
            </Flex>
        );
    }

    return (
        <Box
            align="center"
            justify="center"
            style={{
                height: isCTAActive ? CTA_HEIGHT : 0,
                backgroundColor: isAlert
                    ? theme.color.rgba(theme.color.red, 0.7)
                    : theme.color.rgba(theme.color.white, 0.2),
                paddingHorizontal: theme.spacing.smallest,
                overflow: "hidden",
                transition: "height 0.5s",
            }}
        >
            <Text
                smaller
                smallest={media.isMobile}
                style={{ textAlign: "center" }}
            ></Text>
        </Box>
    );
});
