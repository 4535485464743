import { Avatar } from "@atoms/Avatar";
import { Box, Flex } from "@atoms/Flex";
import { Text } from "@atoms/Text";
import { useAppContext } from "@contexts/app";
import { useMatchUser } from "@contexts/hooks/useMatchUser";
import { useReferrals } from "@contexts/hooks/useReferrals";
import { useNotificationContext } from "@contexts/notification";
import { Button } from "@molecules/Button";
import { InputRef } from "@molecules/Input";
import { Statistic } from "@molecules/Statistic";
import { List } from "@organisms/List";
import { LIST_ITEM_HEIGHT } from "@screens/Trade/components/List/utils";
import { formatAddress, formatPrice } from "@utils";
import { LpShare, UserFe } from "gql/graphql";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { ListRenderItem, Pressable } from "react-native";
import { useTheme } from "styled-components/native";
import { formatUnits } from "viem";
import { useAccount } from "wagmi";
import { Icon } from "@atoms/Icon";
import { Image } from "@atoms/Image";

type LpShareWithUserFE = LpShare & { userFE: UserFe };

const openPopup = (url: string) => {
    const width = 500;
    const height = 500;
    const left = (window.screen.width - width) / 2;
    const top = (window.screen.height - height) / 2;

    window.open(
        url,
        "_blank",
        `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=yes, resizable=yes, copyhistory=no, width=${width}, height=${height}, top=${top}, left=${left}`
    );
};

export const Referrals = ({ onClose }: any) => {
    const theme = useTheme();
    const { userFE, refetchUser } = useAppContext();
    const { address } = useAccount();
    const { notify } = useNotificationContext();
    const [expanded, setExpanded] = useState(false);
    const {
        referredUsers,
        totalReferrals,
        totalReferralDeposits,
        totalReferralVolume,
        isLoading,
        isLoadingReferredUsers,
    } = useReferrals(address);

    useEffect(() => {
        if (address) {
            refetchUser();
        }
    }, [address, refetchUser]);

    const refCode = useMemo(() => {
        return userFE?.referralCode || userFE?.accessCode || "";
    }, [userFE]);

    const referralUrl = useMemo(() => {
        if (userFE?.referralCode) {
            const currentUrl = window.location.href;
            const refParam = "ref=" + (userFE?.referralCode || "");
            const separator = currentUrl.includes("?") ? "&" : "?";
            const newUrl =
                "https://ostium.app/trade?from=CL&to=USD&" +
                (refParam ? refParam : "");
            return `${newUrl}` ?? "";
        }
        return "";
    }, [userFE]);

    const inputRef = useRef<InputRef>(null);

    const onCopy = useCallback(() => {
        notify({
            title: "Referral link copied to clipboard",
        });
        console.warn(inputRef.current);
        inputRef.current?.input?.focus?.();
        navigator.clipboard.writeText(referralUrl);
    }, [notify, referralUrl]);

    const openTelegram = (referralUrl?: string) => {
        console.log("referralUrlTe", referralUrl);
        const encodedUrl = encodeURIComponent(referralUrl as string);
        openPopup(`https://t.me/share/url?url=${encodedUrl}`);
    };

    const openTwitter = (referralUrl?: string) => {
        console.log("referralUrlT", referralUrl);
        const encodedUrl = encodeURIComponent(referralUrl as string);
        openPopup(`https://x.com/intent/tweet?text=${encodedUrl}`);
    };

    const onCodeCopy = useCallback(() => {
        notify({
            title: "Access code copied to clipboard",
        });
        console.warn(inputRef.current);
        inputRef.current?.input?.focus?.();
        //@ts-ignore
        navigator.clipboard.writeText(refCode);
    }, [notify, refCode]);

    return (
        <Box
            style={{
                padding: theme.spacing.bigger,
                paddingTop: 0,
            }}
            gap={theme.spacing.bigger}
        >
            <Text
                smaller
                color={theme.color.rgba(theme.color.white, 0.7)}
                lineHeight={theme.spacing.bigger}
            >
                Earn trading rewards when you successfully refer a friend to
                deposit or trade on Ostium.
            </Text>
            <Flex
                align="center"
                gap={theme.spacing.smaller}
                justify="space-between"
                style={{
                    borderRadius: theme.spacing.smaller,
                    background: "#1B1B1B",
                    padding: theme.spacing.small,
                }}
            >
                <Flex align="center" gap={theme.spacing.smaller}>
                    <Text color="rgba(208, 219, 218, 0.60)" smaller>
                        Access code
                    </Text>
                    <Text ref={inputRef} color="white" smaller align="left">
                        {refCode}
                    </Text>
                </Flex>
                <Flex align="center" gap={theme.spacing.smaller}>
                    <Pressable
                        onPress={() =>
                            openTwitter(
                                `Use my access code ${refCode} to start trading on Ostium` ||
                                    "https://ostium.app/trade"
                            )
                        }
                    >
                        <Image
                            source={`/assets/twitter.png`}
                            priority
                            height={20}
                            width={20}
                            fill={true}
                            alt={`/twitter`}
                        />
                    </Pressable>
                    <Pressable
                        onPress={() =>
                            openTelegram(
                                `Use my access code ${refCode} to start trading on Ostium` ||
                                    "https://ostium.app/trade"
                            )
                        }
                    >
                        <Image
                            source={`/assets/telegram.png`}
                            priority
                            height={11}
                            width={13}
                            fill={true}
                            alt={`/telegram`}
                        />
                    </Pressable>
                    <Pressable onPress={onCodeCopy}>
                        <Image
                            source={`/assets/copy.png`}
                            priority
                            height={16}
                            width={16}
                            fill={true}
                            alt={`/copy`}
                        />
                    </Pressable>
                </Flex>
                {/* <Input
                    // @ts-ignore
                    ref={inputRef}
                    value={referralUrl}
                    type="text"
                    autoFocus
                    selectTextOnFocus
                    style={{
                        fontSize: theme.text.small,
                        borderWidth: 0,
                        backgroundColor: "#1B1B1B",
                        paddingHorizontal: 0,
                        paddingVertical: 0,
                        borderColor: "#1B1B1B",
                    }}
                    renderRight={
                        <Pressable onPress={onCopy}>
                            <Icon
                                name="copy"
                                size={16}
                                color={theme.color.rgba(theme.color.white, 0.4)}
                            />
                        </Pressable>
                    }
                /> */}
            </Flex>

            <Flex
                align="center"
                gap={theme.spacing.smaller}
                justify="space-between"
                style={{
                    borderRadius: theme.spacing.smaller,
                    background: "#1B1B1B",
                    padding: theme.spacing.small,
                }}
            >
                <Text color="rgba(208, 219, 218, 0.60)" smaller>
                    {"https://"}
                    <Text ref={inputRef} color="white" smaller>
                        {refCode
                            ? `ostium.app/trade?ref=${refCode}`
                            : `ostium.app/trade`}
                    </Text>
                </Text>

                <Flex align="center" gap={theme.spacing.smaller}>
                    <Pressable
                        onPress={() =>
                            openTwitter(
                                referralUrl || "https://ostium.app/trade"
                            )
                        }
                    >
                        <Image
                            source={`/assets/twitter.png`}
                            priority
                            height={20}
                            width={20}
                            fill={true}
                            alt={`/twitter`}
                        />
                    </Pressable>
                    <Pressable
                        onPress={() =>
                            openTelegram(
                                referralUrl || "https://ostium.app/trade"
                            )
                        }
                    >
                        <Image
                            source={`/assets/telegram.png`}
                            priority
                            height={11}
                            width={13}
                            fill={true}
                            alt={`/telegram`}
                        />
                    </Pressable>
                    <Pressable onPress={onCopy}>
                        <Image
                            source={`/assets/copy.png`}
                            priority
                            height={16}
                            width={16}
                            fill={true}
                            alt={`/copy`}
                        />
                    </Pressable>
                </Flex>
            </Flex>

            <Box
                style={{
                    marginTop: theme.spacing.big,
                    marginLeft: theme.spacing.tiny,
                }}
                gap={theme.spacing.biggest}
            >
                <Pressable onPress={() => setExpanded(!expanded)}>
                    <Flex
                        direction="row"
                        align="center"
                        style={{
                            cursor: "pointer",
                        }}
                        gap={theme.spacing.smaller}
                    >
                        <Icon
                            name="caret"
                            color={theme.color.rgba(theme.color.primary, 0.6)}
                            size={10}
                            rotate={expanded ? 90 : 0}
                        />
                        <Text
                            smaller
                            semiBold
                            color={theme.color.rgba(theme.color.white, 0.7)}
                        >
                            See past referrals
                        </Text>
                    </Flex>
                </Pressable>
                <Box
                    style={{
                        height: expanded ? "50px" : "0px",
                        overflow: "hidden",
                        transition: "height 0.5s ease-in-out",
                    }}
                >
                    <Flex
                        gap={theme.spacing.smaller}
                        style={{
                            paddingLeft: 16,
                            paddingRight: 16,
                        }}
                        justify="space-between"
                    >
                        <Statistic
                            label="Referred Users"
                            value={totalReferrals}
                            loading={isLoading}
                        />
                        <Statistic
                            label="Referred Deposits"
                            value={`${formatPrice(totalReferralDeposits, {
                                currency: false,
                                decimals: 0,
                            })} USDC`}
                            loading={isLoadingReferredUsers}
                        />
                        {/* <Statistic
                            label="Referred Volume"
                            value={`${formatPrice(totalReferralVolume, {
                                currency: false,
                                decimals: 0,
                            })} USDC`}
                            loading={isLoadingReferredUsers}
                        /> */}
                    </Flex>
                </Box>
            </Box>

            <Flex gap={theme.spacing.medium} justify="end">
                <Button text="Close" primary={false} onPress={onClose} />
                <Button text="Copy Link" primary onPress={onCopy} />
            </Flex>

            {/* <Box
                style={{
                    minHeight: 110,
                    maxHeight: 320,
                    borderRadius: theme.radius.big,
                    backgroundColor: theme.color.rgba(theme.color.white, 0.05),
                }}
            >
                <ReferralsList
                    isLoading={isLoading}
                    lpShares={referredUsers?.lpShares}
                />
            </Box> */}
            {/* <Button
                big
                text="Copy URL"
                onPress={onCopy}
                primary
                stroke
                noBorder
            /> */}
        </Box>
    );
};

const ReferralsList = ({
    lpShares = [],
    isLoading,
}: {
    lpShares: LpShareWithUserFE[];
    isLoading: boolean;
}) => {
    const theme = useTheme();

    const { matchedWithUserFE } = useMatchUser<LpShareWithUserFE>(
        lpShares?.map((item: LpShare) => item.user.toLowerCase()),
        lpShares,
        "user"
    );

    const parsedLpSharesWithUser = useMemo(() => {
        return matchedWithUserFE.sort((a, b) => {
            return (
                Number(formatUnits(b.lockedAssets, 6)) -
                Number(formatUnits(a.lockedAssets, 6))
            );
        });
    }, [matchedWithUserFE]);

    const renderReferral: ListRenderItem<LpShareWithUserFE> = useMemo(
        () =>
            ({ item, index }) => {
                const isLast = index === lpShares.length - 1;

                const username = item?.userFE?.username
                    ? item?.userFE?.username
                    : formatAddress(item.user);

                return (
                    <Flex
                        gap={theme.spacing.big}
                        justify="space-between"
                        align="center"
                        style={{
                            paddingHorizontal: theme.spacing.bigger,
                            height: LIST_ITEM_HEIGHT,
                            borderRadius: theme.radius,
                            borderBottomWidth: isLast ? 0 : 1,
                            borderBottomColor: theme.color.rgba(
                                theme.color.white,
                                0.1
                            ),
                        }}
                    >
                        <Flex align="center" gap={theme.spacing.big}>
                            <Avatar string={item.user} />
                            <Box gap={theme.spacing.tiny}>
                                <Text bold smaller color={theme.color.white}>
                                    {username}
                                </Text>
                            </Box>
                        </Flex>
                        <Box>
                            <Text small bold color={theme.color.white}>
                                {`${formatPrice(
                                    Number(formatUnits(item.lockedAssets, 6)),
                                    { currency: false, decimals: 0 }
                                )} USDC`}
                            </Text>
                        </Box>
                    </Flex>
                );
            },
        [
            lpShares?.length,
            theme.color,
            theme.radius,
            theme.spacing.big,
            theme.spacing.bigger,
            theme.spacing.tiny,
        ]
    );

    const renderEmpty = useMemo(() => {
        return (
            <Box
                style={{
                    padding: theme.spacing.bigger,
                    borderRadius: theme.radius,
                }}
            >
                <Text
                    smaller
                    color={theme.color.white}
                    lineHeight={theme.spacing.bigger}
                >
                    No referred users
                </Text>
            </Box>
        );
    }, [theme.color, theme.radius, theme.spacing.bigger]);

    return (
        <List
            data={parsedLpSharesWithUser}
            renderItem={renderReferral}
            renderEmpty={renderEmpty}
            isLoading={isLoading}
            style={{
                minHeight: 100,
            }}
        />
    );
};
